/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import { MountChildren, Tab, WidgetArea } from '../types'
import { useData } from '../hooks/useData'
import { useLocation } from '../hooks/useLocation'
import { useObserver } from '../hooks/useObserver'
import { TabsNavigation } from './TabsNavigation'
import { MountPoint } from './MountPoint'

type TMainProps = {
  areas: WidgetArea[]
  mountPointId: string
  mountChildren: MountChildren
}

export const Main = ({ areas, mountPointId, mountChildren }: TMainProps) => {
  const location = useLocation()
  const { tabs, mainWidget } = useData()
  const { eventBus, eventBusID } = useObserver()
  const [currentTab, setCurrentTab] = useState<Tab>(null!)

  const currentWidget = useMemo(() => {
    if (!tabs.length) return null
    const currentWidgetName = tabs.find(({ link }) => (
      link && location.pathname.includes(link)
    ))?.widget || mainWidget

    const currentWidget = areas.find(({ name }) => name === currentWidgetName)

    return currentWidget || null
  }, [areas, location, tabs])

  useEffect(() => {
    return () => {
      eventBus && eventBus.unsubscribe(eventBusID, 'switchTab')
    }
  }, [eventBus])

  return (
    <div style={{ overflow: 'hidden' }}>
      <main css={mainCSS}>
        <TabsNavigation
          mountPointId={mountPointId}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
        {currentWidget && currentTab &&
          <MountPoint
            mountChildren={mountChildren}
            widget={currentWidget}
            currentTab={currentTab}
          />
        }
      </main>
    </div>
  )
}

const mainCSS = css`
  margin-top: calc(94rem/var(--bfs));
  margin-bottom: calc(30rem/var(--bfs));
  height: calc(100vh - 124rem/var(--bfs));
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
`

import React from 'react'
import { ApiRepositoryProvider } from './hooks/useApiRepository'
import { LocationProvider } from './hooks/useLocation'
import { ObserverProvider } from './hooks/useObserver'
import { LocaleProvider } from './hooks/useLocale'
import { DataProvider } from './hooks/useData'
import { Main } from './components/Main'
import { WidgetConfig } from './types'

export const Root = ({
  props: { rootUrl, entityType, mountPointId, lib, eventBus },
  mountChildren,
  areas
}: WidgetConfig) => {
  return (
    <ApiRepositoryProvider lib={lib} rootUrl={rootUrl}>
      <LocaleProvider>
        <ObserverProvider eventBus={eventBus}>
          <DataProvider entityType={entityType}>
            <LocationProvider>
              <Main
                areas={areas}
                mountPointId={mountPointId}
                mountChildren={mountChildren}
              />
            </LocationProvider>
          </DataProvider>
        </ObserverProvider>
      </LocaleProvider>
    </ApiRepositoryProvider>
  )
}

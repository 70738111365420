import './set-public-path'
import React, { ErrorInfo } from 'react'
import ReactDOM from 'react-dom'
// @ts-ignore
import singleSpaReact from 'single-spa-react'
import { Root } from './Root'

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err: Error, info: ErrorInfo, props: any) {
    // https://reactjs.org/docs/error-boundaries.html
    console.error(err, info, props)
    return React.createElement('div', null, 'Some error')
  }
})

export const bootstrap = lifecycles.bootstrap
export const mount = lifecycles.mount
export const unmount = lifecycles.unmount

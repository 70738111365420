/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { history } from '@netvision/lib-history'
import { Tab } from '../types'
import { useLocale } from '../hooks/useLocale'
import { useLocation } from '../hooks/useLocation'
import { useApiRepository } from '../hooks/useApiRepository'
import { useData } from '../hooks/useData'
import { useObserver } from '../hooks/useObserver'
import { name } from '../../package.json'

type TTabsNavigationProps = {
  mountPointId: string
  currentTab: Tab
  setCurrentTab: Dispatch<SetStateAction<Tab>>
}

export const TabsNavigation = ({ mountPointId, currentTab, setCurrentTab }: TTabsNavigationProps) => {
  const location = useLocation()
  const { $t } = useLocale()
  const { rootUrl } = useApiRepository()
  const { eventBus, eventBusID } = useObserver()
  const { tabs } = useData()

  const isActiveTab = useCallback((tab: Tab) => tab === currentTab, [currentTab])

  useEffect(() => {
    if (tabs.length) {
      setCurrentTab(
        tabs.find(({ link }) => link && location.pathname.includes(link))
          || tabs[0]
      )
    }
  }, [tabs])

  useEffect(() => {
    if (!eventBus || !currentTab) return

    if ('link' in currentTab) {
      history.push(`${rootUrl}${currentTab.link}`)
    }

    eventBus.notify(eventBusID, 'switchTab', {
      publisher: name,
      occurrenceTime: Date.now(),
      data: currentTab.title
    })
  }, [currentTab, eventBus])

  return (
    <header
      className="p-tabview p-component"
      css={tabPanelStyles}
    >
      <div className="p-tabview-nav-container">
        <nav
          id="tabNavbar"
          className="p-tabview-nav-content"
        >
          <ul
            className="p-tabview-nav"
            role="tablist"
          >
            {tabs.map((tab) => (
              <li
                key={tab.title}
                className="p-tabview-nav-item"
              >
                <button
                  className={`p-tabview-nav-link ${isActiveTab(tab) ? 'active' : ''}`}
                  onClick={() => setCurrentTab(tab)}
                >{$t(tab.title)}</button>
              </li>
            ))}
          </ul>
          <div id={mountPointId} />
        </nav>
      </div>
    </header>
  )
}

const tabPanelStyles = css`
.p-tabview-nav-container {
  padding: 0 calc(30rem/var(--bfs));
  
  .p-tabview-nav-content {
    display: flex;
    justify-content: space-between;
    border-bottom: calc(1rem / var(--bfs)) solid var(--text-color-secondary);

    .p-tabview-nav {
      border: 0;

      &-link {
        position: relative;
        font-size: var(--font-size-h2);
        overflow: inherit;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4rem;
        border: 0;
        
        &.active {
          font-size: var(--font-size-h1);
          color: var(--text-color);

          &::before {
            content: "";
            position: absolute;
            bottom: calc(1rem / var(--bfs) + calc(1rem / var(--bfs)));
            left: 0px;
            transform: translateY(100%);
            height: calc(4rem / var(--bfs));
            width: 100%;
            background: var(--primary-color);
          }
        }
      }
    }
  }
}
`

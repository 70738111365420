/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { MutableRefObject, useEffect, useRef } from 'react'
import { MountChildren, Tab, WidgetArea } from '../types'
import { useObserver } from '../hooks/useObserver'

type TMountPointProps = {
  widget: WidgetArea
  mountChildren: MountChildren
  currentTab: Tab
}

export const MountPoint = ({ widget, mountChildren, currentTab }: TMountPointProps) => {
  const { eventBusID } = useObserver()
  const mountingPointRef: MutableRefObject<HTMLDivElement | null> = useRef(null)

  useEffect(() => {
    const { current: mountingPoint } = mountingPointRef
    if (mountingPoint && widget) {
      return mountChildren(mountingPoint, widget.children.map((child) => ({
        ...child,
        props: {
          ...child.props,
          eventBusID,
          widget,
          initView: currentTab.title
        }
      })))
    }
    return
  }, [widget])

  return (
    <div css={tabPanelCSS}>
      <div
        ref={mountingPointRef}
        css={mountPointCSS}
      />
    </div>
  )
}

const mountPointCSS = css`
  margin-top: 2rem;
  font-size: 2rem;
  overflow: hidden;

  .single-spa-parcel-container {
    height: 100%;
    overflow: hidden;

    & > div {
      margin: 0;
      padding: 0;
      height: 100%;
      overflow: hidden;
    }
  }
`

const tabPanelCSS = css`
  padding: 0 calc(30rem/var(--bfs));
  display: grid;
  grid-template-rows: 1fr;
  overflow: hidden;
`

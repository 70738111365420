import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { LocaleCodes, TabsSchema } from '../types'
import { get } from 'lodash-es'

interface LocaleProviderProps {
  children: ReactNode
}

interface LocaleContext {
  locale: LocaleCodes
  setLocale: (locale: LocaleCodes) => void
  setExternalLocales: (value: TabsSchema['locales']) => void
  $t: <T,>(path: string, ...rest: Array<string | number>) => T | string
}

const LocaleContext = createContext<LocaleContext>({
  locale: 'ru',
  setLocale: (locale: LocaleCodes) => {},
  setExternalLocales: () => {},
  $t: <T,>(path: string, ...rest: Array<string | number>): T | string => path,
})

export const useLocale = () => useContext(LocaleContext)

export const LocaleProvider = ({ children }: LocaleProviderProps) => {
  const [value, setValue] = useState<TabsSchema['locales']>({} as TabsSchema['locales'])
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [locale, setLocale] = useState<LocaleCodes>('ru')
  const $t = <T,>(path: string, ...rest: Array<string | number>): T | string => {
    const data = get(value, `${locale}.${path}`)
    if (typeof data !== 'string') return data as T
    const message: string = String(data)
    return !rest.length ?
      message :
      message.split(' ').reduce((acc, next) => {
        acc.push(
          next.includes('{param}') ?
            next.replace('{param}', String(rest.shift())) :
            next
        )
        return acc
      }, [] as Array<string | number>).join(' ')
  }

  const setExternalLocales = (value: TabsSchema['locales']) => {
    setValue(value)
  }

  useEffect(() => {
    if (isFirstRender) {
      setLocale('ru')
      setIsFirstRender(false)
    }
  }, [isFirstRender, locale])

  return (
    <LocaleContext.Provider value={{ $t, locale, setLocale, setExternalLocales }}>
      {children}
    </LocaleContext.Provider>
  )
}

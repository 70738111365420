import React, { createContext, useEffect, useState, useContext, ReactNode } from 'react'
import { history, Location } from '@netvision/lib-history'
import { useApiRepository } from './useApiRepository'
import { useData } from './useData'

type TLocationProviderProps = {
  children: ReactNode
}

const LocationContext = createContext<Location>(null!)

export const LocationProvider = ({ children }: TLocationProviderProps) => {
  const { tabs } = useData()
  const { rootUrl } = useApiRepository()
  const [location, setLocation] = useState(history.location)

  useEffect(() => {
    if (location.pathname.replaceAll('/', '') === rootUrl?.replaceAll('/', '')) {
      const firstLocation = tabs.find(({ link }) => link)?.link
      firstLocation && history.push(`${rootUrl}${firstLocation}`)
    } else {
      setLocation(location)
    }
    return history.listen((location) => {
      setLocation((prev) => (prev.key === location.key ? prev : location))
    })
  }, [tabs])

  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  )
}

export const useLocation = () => useContext(LocationContext)

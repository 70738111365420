import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { GlobalEventBus } from '@netvision/lib-types-frontend'

interface ObserverProviderProps {
  eventBus: GlobalEventBus
  children: ReactNode
}

interface ObserverContext {
  eventBusID: string
  eventBus: GlobalEventBus
}

const ObserverContext = createContext<ObserverContext>({
  eventBusID: '',
  eventBus: {} as GlobalEventBus
})

export const useObserver = () => useContext(ObserverContext)

export const ObserverProvider = ({ eventBus, children }: ObserverProviderProps) => {
  const [eventBusID, setEventBusID] = useState<string>(null!)
  const [eBus, setEBus] = useState<GlobalEventBus>(null!)

  useEffect(() => {
    if (!eBus || !eventBusID) {
      setEventBusID(eventBus.addUniqueSubscriberMap())
      setEBus(eventBus)
    }
  }, [eventBus])

  return (
    <ObserverContext.Provider value={{ eventBus: eBus, eventBusID }}>
      {children}
    </ObserverContext.Provider>
  )
}

import React, { createContext, ReactNode, useContext } from 'react'
import { repositoryGetter, TUnionRepo, TLibProp } from '@netvision/lib-api-repo'

interface TApiRepositoryProviderProps {
  rootUrl: string
  children: ReactNode
  lib: TLibProp | undefined
}

interface ApiRepositoryContext {
  api: TUnionRepo
  rootUrl: string
  lib: TLibProp | undefined
}

const ApiRepositoryContext = createContext<ApiRepositoryContext>({
  api: {} as TUnionRepo,
  lib: {} as TLibProp,
  rootUrl: ''
})

export const useApiRepository = () => useContext(ApiRepositoryContext)

export const ApiRepositoryProvider = ({ children, lib, rootUrl }: TApiRepositoryProviderProps) => {
  const api: TUnionRepo = repositoryGetter(lib)
  
  return (
    <ApiRepositoryContext.Provider value={{ api, lib, rootUrl }}>
      {children}
    </ApiRepositoryContext.Provider>
  )
}
